import React, { useEffect } from "react"
import cx from "classnames"
import ScrollReveal from "scrollreveal"

import Layout from "../components/layout"
import { IMG } from "../const"
import "../assets/styles/commons/_heading-numbers.scss"
import "../assets/styles/commons/lists/_common__list__techLogos-rounded.scss"
import "../assets/styles/commonsGlossy.scss"
import * as styles from "../assets/styles/ageline.module.scss"
import ContactsForm from "../components/contact-form/contacts"
import { TECHNOLOGIES, WORKS } from "../data/ageLine"

const AgeLine = () => {
  useEffect(() => {
    ScrollReveal().reveal(`#Technologies li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
  }, [])

  return (
    <Layout pageTitle="AgeLine">
      {() => {
        return (
          <>
            <main className={cx(styles.ageLineBlock, "glossy")}>
              <article>
                <div className={styles.heading}>
                  <div className={styles.heading_text}>
                    <h1>Age Line – Living, Caregiving, Working.</h1>
                    <p>
                      Age Line is a platform intended to&nbsp;be an open-source
                      set of solutions to an aging society's challenges.
                    </p>
                    <p>
                      The Age line is a massive user platform that helps seniors
                      find their ideal living places. The platform offers users
                      an accurate assessment of sites to&nbsp;live based on
                      complex user reviews and average location rating scores by
                      leading evaluation platforms in the US.
                    </p>
                  </div>
                  <div className={styles.heading_img}>
                    <picture>
                      <source
                        media="(max-width: 900px)"
                        srcSet={`${IMG.AGELINE}/heading-mobile.avif,	${IMG.AGELINE}/heading-mobile@2.avif 2x, 	 ${IMG.AGELINE}/heading-mobile@3.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 900px)"
                        srcSet={`${IMG.AGELINE}/heading-mobile.webp,	${IMG.AGELINE}/heading-mobile@2.webp 2x, 	 ${IMG.AGELINE}/heading-mobile@3.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 900px)"
                        srcSet={`${IMG.AGELINE}/heading-mobile.png,		${IMG.AGELINE}/heading-mobile@2.png 2x,		${IMG.AGELINE}/heading-mobile@3.png 3x`}
                      />

                      <source
                        media="(min-width: 901px)"
                        srcSet={`${IMG.AGELINE}/heading.avif,	${IMG.AGELINE}/heading@2.avif 2x,	${IMG.AGELINE}/heading@3.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 901px)"
                        srcSet={`${IMG.AGELINE}/heading.webp,	${IMG.AGELINE}/heading@2.webp 2x,	${IMG.AGELINE}/heading@3.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width:901px)"
                        srcSet={`${IMG.AGELINE}/heading.png, ${IMG.AGELINE}/heading@2.png 2x,  	${IMG.AGELINE}/heading@3.png 3x`}
                      />
                      <img
                        src={`${IMG.AGELINE}/heading@2.png`}
                        alt="AgeLine web page"
                        width="568"
                        height="596"
                      />
                    </picture>
                  </div>
                </div>

                <div className={styles.feedback}>
                  <blockquote>
                    The LaSoft team continues to exceed my expectations with
                    their unwavering dedication and hard work. Their
                    communication makes collaboration effortless, ensuring every
                    project detail is thoroughly understood. Meeting deadlines
                    consistently, they not only fulfilled project requirements
                    but exceeded them with expertise. I highly recommend their
                    services to anyone seeking a partner that combines hard
                    work, effective communication, attention to detail, and
                    professionalism.
                  </blockquote>
                  <p className={styles.name}>Emily Johnson</p>
                  <p className={styles.jobTitle}>Digital Marketer</p>
                </div>

                <section className={styles.challenge}>
                  <h2 className={cx(styles.numerous, "numerous")}>
                    <span>Challenge</span>
                  </h2>
                  <p className={styles.strong}>
                    In 2017, the Age Line project was a great idea: to create a
                    platform for people over 50 years old to find the best, most
                    comfortable, and most age-friendly places for{" "}
                    <strong>retirement.</strong>
                  </p>
                  <p>
                    LaSoft's challenge was to build the Age Line platform's
                    technological implementation.
                  </p>
                </section>

                <section className={styles.realization}>
                  <div className={cx(styles.numerous, "numerous")}>
                    <span>Realization</span>
                  </div>
                  <div className={styles.twoColumns}>
                    <div>
                      <h2>Solution that we proposed</h2>
                      <p>
                        At the heart of the project is a review: of the place,
                        the city, on social structures, including
                        transportation, hospitals, and nursing homes. A very
                        complex formula calculates this review through a rating
                        system.
                      </p>
                    </div>
                    <div>
                      <p className={styles.strong}>
                        We used artificial intelligence to optimize the process
                        of selecting images of cities and optimize
                        $&nbsp;300&nbsp;000 of client's budget.
                      </p>
                      <p>
                        Each city receives its rating based on official sources
                        and reviews. This decision allows users to give a very
                        accurate assessment of the suitability of a place for
                        living.
                      </p>
                    </div>
                  </div>
                  <div className={styles.twoColumns}>
                    <div className={styles.techStack}>
                      <ul
                        className="common__list__techLogos-rounded"
                        id="Technologies"
                      >
                        {TECHNOLOGIES.map(technology => (
                          <li key={technology.id}>
                            <figure>
                              <img
                                src={`${IMG.ICONS}/logos/${technology.image}`}
                                width="32"
                                height="32"
                                loading="lazy"
                                alt={technology.name}
                              />
                              <figcaption>{technology.name}</figcaption>
                            </figure>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.techStack__pic}>
                      <picture className={styles.techStack__pic1}>
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/1.avif,	${IMG.DEDICATED_TEAM}/code/1@2x.avif 2x,	${IMG.DEDICATED_TEAM}/code/1@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/1.webp,	${IMG.DEDICATED_TEAM}/code/1@2x.webp 2x,	${IMG.DEDICATED_TEAM}/code/1@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/1.jpg,	${IMG.DEDICATED_TEAM}/code/1@2x.jpg 2x,		${IMG.DEDICATED_TEAM}/code/1@3x.jpg 3x`}
                        />
                        <img
                          src={`${IMG.DEDICATED_TEAM}/code/1@3x.jpg`}
                          alt="React page code"
                          width="310"
                          height="372"
                          loading="lazy"
                        />
                      </picture>
                      <picture className={styles.techStack__pic2}>
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/2.avif,	${IMG.DEDICATED_TEAM}/code/2@2x.avif 2x,	${IMG.DEDICATED_TEAM}/code/2@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/2.webp,	${IMG.DEDICATED_TEAM}/code/2@2x.webp 2x,	${IMG.DEDICATED_TEAM}/code/2@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/2.jpg,	${IMG.DEDICATED_TEAM}/code/2@2x.jpg 2x,		${IMG.DEDICATED_TEAM}/code/2@3x.jpg 3x`}
                        />
                        <img
                          src={`${IMG.DEDICATED_TEAM}/code/2@3x.jpg`}
                          alt="React helmet code"
                          width="315"
                          height="330"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </section>
                <section className={styles.result}>
                  <div className={styles.twoColumns}>
                    <div>
                      <div className={cx(styles.numerous, "numerous")}>
                        <span>The job was done</span>
                      </div>
                      <h2>Result</h2>
                      <p className={styles.strong}>
                        Today, the platform continues to develop and expand its
                        functionality. Now the platform is used by more than
                        20,000 active users, and the number of reviews has
                        reached 60,000.
                      </p>
                      <p>
                        The platform is used by pensioners, retiring people, and
                        people looking for ideal places to live for their
                        parents and close relatives.
                      </p>
                    </div>
                    <div className={styles.image}>
                      <picture>
                        <source
                          media="(max-width: 900px)"
                          srcSet={`${IMG.AGELINE}/result-mobile.avif,	${IMG.AGELINE}/result-mobile@2.avif 2x, 	 ${IMG.AGELINE}/result-mobile@3.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 900px)"
                          srcSet={`${IMG.AGELINE}/result-mobile.webp,	${IMG.AGELINE}/result-mobile@2.webp 2x, 	 ${IMG.AGELINE}/result-mobile@3.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 900px)"
                          srcSet={`${IMG.AGELINE}/result-mobile.jpg,		${IMG.AGELINE}/result-mobile@2.jpg 2x,		${IMG.AGELINE}/result-mobile@3.jpg 3x`}
                        />

                        <source
                          media="(min-width: 901px)"
                          srcSet={`${IMG.AGELINE}/result.avif,	${IMG.AGELINE}/result@2.avif 2x,	${IMG.AGELINE}/result@3.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 901px)"
                          srcSet={`${IMG.AGELINE}/result.webp,	${IMG.AGELINE}/result@2.webp 2x,	${IMG.AGELINE}/result@3.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width:901px)"
                          srcSet={`${IMG.AGELINE}/result.jpg, ${IMG.AGELINE}/result@2.jpg 2x,  	${IMG.AGELINE}/result@3.jpg 3x`}
                        />
                        <img
                          src={`${IMG.AGELINE}/result@2.jpg`}
                          alt="Result"
                          width="478"
                          height="596"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </section>
                <section className={styles.howWorks}>
                  <h2>How Age Line works</h2>
                  <div className={styles.worksList}>
                    {WORKS.map(item => (
                      <div
                        key={item.title}
                        className={
                          item.title === "Working" ? styles.higher : ""
                        }
                      >
                        <div className={styles.textBlock}>
                          <h3>{item.title}</h3>
                          <p className={styles.strong}>{item.strongText}</p>
                          {item.text && <p>{item.text}</p>}
                        </div>
                        <div className={styles.imageBlock}>
                          <div className={styles.imageWrapper}>
                            <picture>
                              <source
                                srcSet={`${IMG.AGELINE}/${item.image}.avif,	${IMG.AGELINE}/${item.image}@2.avif 2x,	${IMG.AGELINE}/${item.image}@3.avif 3x`}
                                type="image/avif"
                              />
                              <source
                                srcSet={`${IMG.AGELINE}/${item.image}.webp,	${IMG.AGELINE}/${item.image}@2.webp 2x,	${IMG.AGELINE}/${item.image}@3.webp 3x`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`${IMG.AGELINE}/${item.image}.${item.ext},	${IMG.AGELINE}/${item.image}@2.${item.ext},		${IMG.AGELINE}/${item.image}@3.${item.ext} 3x`}
                              />
                              <img
                                src={`${IMG.AGELINE}/${item.image}@2.${item.ext}`}
                                alt={item.title}
                                width="568"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              </article>
            </main>
            <section className="footerForm">
              <div id="mobile-development-contact-form">
                <ContactsForm formStatID="CommonFooter" />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default AgeLine
